<template>
  <div>
    <titleBar
      :title="'Edit Readme'"
      :img="bot?.avatar"
      back="auto"
      :showBackArrowDesktop="true"
      subtitle=""
      :centered="false"
      theme="white"
      :showSignup="false"
      containerClass="donotuse"
      :showDesktop="true"
      :showAfterScroll="0"
      :scrollTopOnClick="true"
      :inline="false"
      actionButtonStyle="round"
      actionButtonRoundBg="transparent"
      iconPack="fas"
    />

    <div class="sectionNA">
      <div class="containerNA NHAw800">
        <header>
          <h2 class="title is-2">
            <span>Edit Readme</span>
            <span class="icon">
              <i class="fas fa-file"></i>
            </span>
          </h2>
        </header>

        <div class="content">
          <Editor v-model="bot.readmeHtml" class="editor" />
        </div>

        <hr />

        <pre>{{ bot.readmeHtml }}</pre>
      </div>
    </div>
  </div>
</template>

<script>
import Editor from "@/components/e/EditorV";

// SEE https://tiptap.dev/api/extensions/floating-menu
// we use tiptap2, vue2, and the floating menu extension

var defaultReadme = `<h1>This bot doesn't have a readme</h1>
<p>It's a good idea to write a readme for your bot. It's a great way to introduce yourself and your bot to the community.</p>`;

export default {
  name: "readmeEditor",
  components: {
    Editor,
  },
  mounted() {
    console.log("mounted");
    console.log(this.bot);
    //if empty text, set default.
    if (this.bot.readmeHtml == "") {
      this.bot.readmeHtml = defaultReadme;
    }
  },
  props: {
    bot: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      //   readmeJson: defaultReadme, // "432432432",
    };
  },
};
</script>

<style>
.editor {
  min-height: 400px;
  border: 1px solid #ccc;
}
</style>
